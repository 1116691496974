:root {
  /* Colors */
  --primaryColor: #445569;
  --onPrimaryColor: #fefefe;
  --secondaryColor: #f1cb88;
  --onSecondaryColor: #0f0f0f;
  --backgroundColor: #f1f4fa;
  --onBackgroundColor: #0f0f0f;
  --surfaceColor: #fefefe;
  --onSurfaceColor: #0f0f0f;

  --darkColor: #0f0f0f;
  --lightColor: #fefefe;

  /* z-indices */
  --lowIndex: 1;
  --midIndex: 10;
  --highIndex: 100;
  --higherIndex: 1000;
  --highestIndex: 10000;
  --maxIndex: 100000;

  /* Dimensions */
  --topBarHeight: 90px;
  --sideBarWidth: 300px;
  --sideViewerWidth: 600px;

  /* Shadows */
  --shadow1: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow2: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --shadow3: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  --shadow4: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  --shadow5: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

/* FONTS */

@font-face {
  font-family: "FontRegular";
  src: local("FontRegular"), url("./fonts/Montserrat/Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontBold";
  src: local("FontBold"), url("./fonts/Montserrat/Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontLight";
  src: local("FontLight"), url("./fonts/Montserrat/Light.ttf") format("truetype");
}

@font-face {
  font-family: "FontSemiBold";
  src: local("FontSemiBold"), url("./fonts/Montserrat/SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "FontOrbitronRegular";
  src: local("FontOrbitronRegular"), url("./fonts/Orbitron/Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontOrbitronBold";
  src: local("FontOrbitronBold"), url("./fonts/Orbitron/Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontOrbitronSemiBold";
  src: local("FontOrbitronSemiBold"), url("./fonts/Orbitron/SemiBold.ttf") format("truetype");
}

.font-bold{
  font-family: "FontBold" !important;
}

.font-regular{
  font-family: "FontRegular" !important;
}

.font-light{
  font-family: "FontLight" !important;
}

.font-semi-bold{
  font-family: "FontSemiBold" !important;
}

.font-orbitron-regular{
  font-family: "FontOrbitronRegular" !important;
}

.font-orbitron-bold{
  font-family: "FontOrbitronBold" !important;
}

.font-orbitron-semi-bold{
  font-family: "FontOrbitronSemiBold" !important;
}

/* END of FONTS */

/* GENERAL STYLING */
body,
html {
  width: 100%;
  height: auto;
  font-family: "FontRegular";
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  font-size: 1em;
}

/* Prevent body from when any modal is open */
body.modal-open {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  /* Styles for tablets in portrait and smaller devices */
  body.sidebar-open {
    overflow: hidden;
  }
}


#root {
  width: 100%;
  height: auto;
}

/* UTILITIES */
.text-primary {
  color: var(--primaryColor) !important;
}

.text-secondary {
  color: var(--secondaryColor) !important;
}

.text-background {
  color: var(--backgroundColor) !important;
}

.text-surface {
  color: var(--surfaceColor) !important;
}

.text-dark {
  color: var(--darkColor) !important;
}

.text-light {
  color: var(--lightColor) !important;
}

.bg-gradient {
  background: linear-gradient(30deg, var(--primaryColor), var(--secondaryColor)) !important;
  color: var(--onPrimaryColor);
}

.bg-primary {
  background: var(--primaryColor) !important;
  color: var(--onPrimaryColor) !important;
}

.bg-secondary {
  background: var(--secondaryColor) !important;
  color: var(--onSecondaryColor) !important;
}

.bg-background {
  background: var(--backgroundColor) !important;
  color: var(--onBackgroundColor) !important;
}

.bg-surface {
  background: var(--surfaceColor) !important;
  color: var(--onSurfaceColor) !important;
}

.bg-dark {
  background: var(--darkColor) !important;
  color: var(--lightColor) !important;
}

.bg-light {
  background: var(--lightColor) !important;
  color: var(--darkColor) !important;
}

.btn {
  font-family: "FontSemiBold";
  font-size: 16px;
  
}

.btn-sm {
  font-size: 12px !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  background: var(--primaryColor) !important;
  color: var(--onPrimaryColor) !important;
  border: 2px solid var(--primaryColor) !important;
}

.btn-secondary {
  background: var(--secondaryColor) !important;
  color: var(--onSecondaryColor) !important;
  border: 2px solid var(--secondaryColor) !important;
}

.btn-background {
  background: var(--backgroundColor) !important;
  color: var(--onBackgroundColor) !important;
  border: 2px solid var(--backgroundColor) !important;
}

.btn-surface {
  background: var(--surfaceColor) !important;
  color: var(--onSurfaceColor) !important;
  border: 2px solid var(--surfaceColor) !important;
}

.btn-dark {
  background: var(--darkColor) !important;
  color: var(--lightColor) !important;
  border: 2px solid var(--darkColor) !important;
}

.btn-light {
  background: var(--lightColor) !important;
  color: var(--darkColor) !important;
  border: 2px solid var(--lightColor) !important;
}

.btn:focus, 
.btn:hover {
  outline: none !important;
  background: var(--primaryColor) !important;
  color: var(--onPrimaryColor) !important;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-padded {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.btn-outline-dark {
  color: var(--darkColor) !important;
  border: 2px solid var(--darkColor) !important;
  background: none;
}

.btn-outline-light {
  color: var(--lightColor) !important;
  border: 2px solid var(--lightColor) !important;
  background: none;
}

.btn-outline-primary {
  color: var(--primaryColor) !important;
  border: 2px solid var(--primaryColor) !important;
  background: none;
}

.btn-outline-secondary {
  color: var(--secondaryColor) !important;
  border: 2px solid var(--secondaryColor) !important;
  background: none;
}

.btn-outline-surface {
  color: var(--surfaceColor) !important;
  border: 2px solid var(--surfaceColor) !important;
  background: none;
}

.btn-outline-background {
  color: var(--backgroundColor) !important;
  border: 2px solid var(--backgroundColor) !important;
  background: none;
}

.btn-padded {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mOneLineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mNoMargin {
  margin: 0 !important;
}

.mNoPadding {
  padding: 0 !important;
}

.mSupportLoading {
  min-height: 60px !important;
  min-width: 40px;
  position: relative;
}

.mSupportLoadingFull {
  height: 100% !important;
  width: 100%;
  position: relative;
}

.mShadow1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.mShadow2 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mShadow3 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.mShadow4 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.mShadow5 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mNoScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mNoScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.mMobileOnly {
  display: none;
}

.mDesktopOnly {
  display: block;
}

@media only screen and (max-width: 767px) {
  .mMobileOnly {
    display: block;
  }

  .mDesktopOnly {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}


input,
select,
textarea {
  
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid var(--primaryColor) !important;
}

.card {
  border-radius: 15px !important;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  border: none !important;
  box-shadow: var(--shadow1);
  margin-bottom: 20px;
  overflow: hidden;
}

.card 
.card-title {
  font-size: 18px;
  font-family: "FontSemiBold";
  color: var(--primaryColor);
}

[class^="col-"] {
  
}

.section-title {
  font-size: 32px;
  font-family: "FontLight";
  margin-top: 10px;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.main-section-title {
  font-size: 36px;
  font-family: "FontLight";
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  /* Styles for tablets in portrait and smaller devices */
  .main-section-title {
    font-size: 28px;
  }
}

/* mFormType1 */
.mFormType1 {
  max-width: var(--sideViewerWidth);
  margin: 0 auto;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  padding: 0;
  box-shadow: var(--shadow1);
}

.mFormType1 >
.section-title {
  font-size: 24px;
  font-family: "FontLight";
  margin: 0;
  background: var(--primaryColor);
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--onPrimaryColor);
  width: 100%;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
}

.mFormType1 >
.body {
  padding: 15px;
}

.mFormType1 input,
.mFormType1 select,
.mFormType1 textarea {
  padding: 10px;
}

.mFormType1 label {
  font-size: 14px;
  font-family: "FontSemiBold";
}

.mFormType1 .form-text {
  font-size: 12px;
  font-family: "FontRegular";
}

.mSchoolLogoHolderMin {
  width: 150px;
  height: 150px;
  background-color: var(--backgroundColor);
  border-radius: 50%;
}

.mSchoolLogoHolderLg {
  width: 300px;
  height: 300px;
  background-color: var(--backgroundColor);
  border-radius: 50%;
}

.category-badge-lg {
  font-size: 16px;
  font-family: "FontSemiBold";
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
}

.category-badge-sm {
  font-size: 12px;
  font-family: "FontSemiBold";
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
}

/* end of UTILITIES */


/* Loader */
.Loader {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 15px;
  background: none;
  border: 4px solid var(--onBackgroundColor) !important;
  border-left: 4px solid var(--backgroundColor) !important;
  top: 50%;
  z-index: inherit;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  animation-name: "loading";
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: "loading";
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Backdrop */
.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Slightly dark backdrop */
  z-index: var(--lowIndex); /* Behind all fixed components */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

/* Viewer */
.Viewer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--midIndex);
  overflow: auto;
  top: 0;
  left: 0;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translateY(-100vh); /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;
}

.Viewer.visible {
  opacity: 1;
  transform: translateY(0); /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.Viewer.hiding {
  opacity: 0;
  transform: translateY(-100vh); /* Slide back up */
  visibility: visible; /* Keep visible until animation ends */
  pointer-events: none; /* Disable interactions during hide animation */
}

.Viewer >
.header {
  width: 100%;
  position: absolute;
  height: var(--topBarHeight);
  top: 0;
  left: 0;
  
}

.Viewer >
.body {
  width: 100%;
  position: absolute;
  height: calc(100% - var(--topBarHeight));
  overflow: auto;
  top: var(--topBarHeight);
  left: 0;
  padding: 0;
}

.Viewer >
.body >
.container {
  padding: 20px !important;
}


/* SideViewer */
.SideViewer {
  width: var(--sideViewerWidth);
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--higherIndex);
  overflow: auto;
  top: 0;
  right: 0;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translateX(100vw); /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  /* Styles for tablets in portrait and smaller devices */
  .SideViewer {
    width: 95vw;
  }
}

.SideViewer.visible {
  opacity: 1;
  transform: translateX(0); /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.SideViewer.hiding {
  opacity: 0;
  transform: translateX(100vw); /* Slide back up */
  visibility: visible; /* Keep visible until animation ends */
  pointer-events: none; /* Disable interactions during hide animation */
}

.SideViewer >
.header {
  width: 100%;
  position: absolute;
  height: var(--topBarHeight);
  top: 0;
  left: 0;
  
}

.SideViewer >
.body {
  width: 100%;
  position: absolute;
  height: calc(100% - var(--topBarHeight));
  overflow: auto;
  top: var(--topBarHeight);
  left: 0;
  
}


/* ConfirmDialog */
.ConfirmDialog {
  width: 300px;
  height: 240px;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: var(--maxIndex);
  overflow: hidden;
  top: 50%;
  left: 50%;
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  opacity: 0;
  transform: translate(-100%, -50%); /* Start slightly above the viewport */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;
}

.ConfirmDialog.visible {
  opacity: 1;
  transform: translate(-50%, -50%); /* Slide to original position */
  visibility: visible;
  pointer-events: auto;
}

.ConfirmDialog.hiding {
  opacity: 0;
  transform: translate(-100%, -50%); /* Slide back up */
  visibility: visible; /* Keep visible until animation ends */
  pointer-events: none; /* Disable interactions during hide animation */
}

.ConfirmDialog >
.header {
  width: 100%;
  position: absolute;
  height: 20%;
  top: 0;
  left: 0;
  
}

.ConfirmDialog >
.body {
  width: 100%;
  position: absolute;
  height: 55%;
  overflow: hidden;
  top: 20%;
  left: 0;
  
}

.ConfirmDialog >
.footer {
  width: 100%;
  position: absolute;
  height: 25%;
  overflow: hidden;
  top: 75%;
  left: 0;
  
}

/* SideBar */
.SideBar {
  width: var(--sideBarWidth);
  height: 100%;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--lowIndex);
  padding: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  /* Styles for tablets in portrait and smaller devices */
  .SideBar {
    width: var(--sideBarWidth);
    height: 100%;
    background: none;
    position: fixed;
    top: 0;
    left: calc(var(--sideBarWidth) * -1);
    z-index: var(--lowIndex);
    padding: 10px;
    overflow: hidden;
    transition: left 0.4s ease-in;
  }

  .SideBar[data-open='true'] {
    left: 0;
  }
}

.SideBar >
.body {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 15px;
  background: var(--primaryColor);
  color: var(--onPrimaryColor);
  padding: 15px;
}

.SideBar >
.body >
.logo-holder {
  width: 100%;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid gray;
  margin-bottom: 10px;
}

.SideBar >
.body >
.logo-holder 
img {
  /* filter: brightness(0) invert(1); */
}

.SideBar >
.body >
.pill {
  width: 100%;
  height: calc(var(--topBarHeight) * 0.6);
  padding: 20px;
  display: flex;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 5px;
}

.SideBar >
.body >
.pill >
.icon {
  height: 24px;
  width: 24px;
  margin: 0;
  align-self: center;
}

.SideBar >
.body >
.pill >
.text {
  font-size: 14px;
  margin: 0;
  align-self: center;
  padding-left: 10px;
  font-family: "FontRegular";
}

.SideBar >
.body >
.pill[data-active='true'],
.SideBar >
.body >
.pill:hover {
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
}

/* TopBar */
.TopBar {
  width: calc(100% - var(--sideBarWidth));
  height: var(--topBarHeight);
  background: var(--backgroundColor);
  position: fixed;
  top: 0;
  left: var(--sideBarWidth);
  z-index: var(--lowIndex);
  padding: 10px;
  overflow: hidden;
}

.TopBar >
.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  padding: 15px;
}

.TopBar >
.body 
.mIcon {
  width: 30px;
  height: 30px;
  align-self: center;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  cursor: pointer;
}

.TopBar >
.body 
.mIcon >
.mSymbol {
  font-size: 30px;
  color: var(--primaryColor);
}

@media only screen and (max-width: 767px) {
  /* Styles for tablets in portrait and smaller devices */
  .TopBar {
    width: 100%;
    height: var(--topBarHeight);
    background: var(--backgroundColor);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--lowIndex);
    padding: 10px;
    overflow: hidden;
  }

  .TopBar >
  .body {
    padding-left: 40px;
  }
}

/* MainBody */
.MainBody {
  width: calc(100% - var(--sideBarWidth));
  height: auto;
  min-height: 50vh;
  padding: 0;
  margin: 0;
  margin-left: var(--sideBarWidth);
  margin-top: var(--topBarHeight);
}

.MainBody > 
.container {
  padding-top: 10px !important;
}

@media only screen and (max-width: 767px) {
  /* Styles for tablets in portrait and smaller devices */
  .MainBody {
    width: 100%;
    height: auto;
    min-height: 50vh;
    margin: 0;
    margin-top: var(--topBarHeight);
  }
}

/* MainMenuBtn */
.MainMenuBtn {
  position: fixed;
  z-index: var(--midIndex);
  top: calc(var(--topBarHeight) * 0.5);
  left: 20px;
  height: 40px;
  width: 40px;
  padding: 5px;
  background: var(--surfaceColor);
  border-radius: 50%;
  overflow: hidden;
  transform: translateY(-50%);
  cursor: pointer;
  transition: left 0.4s ease-in;
}

.MainMenuBtn[data-sidebar-open='true'] {
  left: calc(var(--sideBarWidth) - 60px);
}

.MainMenuBtn >
* {
  font-size: 30px;
  margin: 0;
  padding: 0;
  color: var(--primaryColor);
}

/* Login */
.Login {
  width: 100%;
  min-height: 100%;
  padding-top: var(--topBarHeight);
  padding-bottom: var(--topBarHeight);
  padding-left: calc(0.25 * var(--topBarHeight));
  padding-right: calc(0.25 * var(--topBarHeight));
}

.Login >
.body {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0;
}


/* MainLoader */
.MainLoader {
  width: 100vw;
  height: 100vh;
}

/* OverlayLoader */
.OverlayLoader {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: var(--maxIndex);
  top: 0;
  left: 0;
}

/* One */
.One {
  width: 100%;
  padding: 20px;
  height: auto;
  cursor: pointer;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  border-bottom: 3px solid var(--backgroundColor);
}

.One:hover {
  background-color: var(--backgroundColor);
  color: var(--onBackgroundColor);
}